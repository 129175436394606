
.advanced-search-bar-container[data-v-63c8acf8] {
   height: 64px;
   box-shadow: 0px 3px 26px #00000045;
   border-radius: 32px;
   background-color: white;
   padding-top: 10px;
   padding-bottom: 10px;
   padding-left: 20px;
   padding-right: 10px;
   display: none;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
}
.advanced-search-bar-mobile-search-btn[data-v-63c8acf8] {
   width: 100%;
   text-align: center;
   height: 60px;
   background-color: white;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   padding-left: 20px;
   padding-right: 20px;
   border-radius: 30px;
   box-shadow: 0px 3px 26px #00000045;
}
.advanced-search-mobile-daterange-input[data-v-63c8acf8] {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   border: 1px solid #cccccc;
   padding: 10px;
   border-radius: 4px;
   font-size: 16px;
   cursor: pointer;
}
.advanced-search-bar-subcontainer-textsearch[data-v-63c8acf8] {
   max-width: 100%;
   cursor: text;
   flex-grow: 1;
}
.advanced-search-bar-subcontainer[data-v-63c8acf8] {
   max-width: 280px;
   cursor: text;
   flex-grow: 1;
   padding-left: 20px;
   cursor: pointer;
}
.advanced-search-bar-container .advanced-search-bar-subcontainer[data-v-63c8acf8]:not(:first-child) {
   border-left: 1px solid #E6E6E6;
}
.advanced-search-bar-title[data-v-63c8acf8] {
   font-weight: 500;
   font-size: 14px;
}
.advanced-search-bar-subtitle[data-v-63c8acf8] {
   font-weight: 300;
   color: black;
}
.advanced-search-bar-textinput[data-v-63c8acf8] {
   border: none;
   outline: none;
   font-size: 25px;
   background-color: white;
   color: #4A545A;
   max-width: 80%;
}
.positioned-under-casousel[data-v-63c8acf8] {
  z-index: 10;
  position: relative;
  top: -34px;
}
.positioned-under-casousel-desktop[data-v-63c8acf8] {
    position: relative;
    top: -34px;
}
.positioned-under-header[data-v-63c8acf8] {
  margin-top: 20px;
  margin-bottom: 20px;
}
.search-mobile-container[data-v-63c8acf8] {
  position: fixed;
  overflow-y: scroll;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 1000;
  width: 100%;
  min-height: 100vh;
  background-color: white;
  padding: 20px;
}
@media (min-width: 768px) {
.advanced-search-bar-container[data-v-63c8acf8] {
      display: flex;
}
.advanced-search-bar-mobile-search-btn[data-v-63c8acf8] {
      display: none;
}
}
@media (max-width: 768px) {
.positioned-under-casousel[data-v-63c8acf8] {
    top: -100px;
}
.positioned-under-casousel-desktop[data-v-63c8acf8] {
    position: static;
    top: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
}
}
